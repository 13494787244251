/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700'); */

@font-face {
  font-family: CandyChicks;
  src: local("CandyChicks"),
    url(./resources/fonts/CandyChicks.ttf) format("truetype");
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body {
  margin: 0;
  background-color: #f4f7fb;
  max-width: 100%;
  overflow-x: hidden;
}
@media print {
  body {
    background-color: #fff;
  }
}

html {
  overflow-x: hidden;
  background-color: #ffffff;
}

body {
  font-family: "Open Sans";
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  /*for firefox */
  /* scrollbar-color:  #2b2d3c #2b2d3c; */
}

a {
  color: inherit;
}

#root {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Do not allow x overflow */
main {
  max-width: 100vw;
  overflow-x: hidden;
}

@media screen and (max-width: 600px) {
  html {
    font-size: 12px;
  }
}
@media screen and (max-width: 340px) {
  html {
    font-size: 11px;
  }
}
